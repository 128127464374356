body {
  margin: 0;
  padding: 0;
  font-family: "Baloo Tamma 2", cursive;
  font-weight: 400;
  height: 100%;
  font-size: 14px;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

.__react_component_tooltip {
  font-size: 16px;
}

.menuTooltip.__react_component_tooltip.place-bottom::after {
  left: unset;
  right: 18%;
  margin-left: unset;
}

.menuTooltip.__react_component_tooltip.place-bottom::before {
  left: unset;
  right: 18%;
  margin-left: unset;
  margin-right: -2px;
}

.menuTooltip.__react_component_tooltip.type-light.border {
  border: 1px solid #e67766;
}

.menuTooltip.__react_component_tooltip.type-light.border.place-bottom::before {
  border-bottom: 8px solid #e67766;
}

.menuTooltip.__react_component_tooltip.show {
  opacity: 1;
}

.menuTooltip.__react_component_tooltip {
  width: 200px;
}
